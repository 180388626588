<template>
    <div class="tag-feed" :class="CSSClasses">
        <div class="tag-wrapper">
            <Chip :url="topicOrRegionPath" class="tag">
                {{ topicOrRegion?.value?.title }}
            </Chip>
        </div>

        <div class="feed-content">
            <div v-if="featuredItems?.[0]" class="featured-item">
                <Image v-if="featuredImage" v-bind="featuredImage" class="featured-image" />
                <ContentSummary
                    v-bind="featuredItems[0]"
                    :isDescriptionHidden="true"
                    :label="featuredItems[0].label"
                    :people="featuredItems[0].people"
                    :theme="theme"
                    :mediaSource="featuredItems[0]?.mediaSource"
                    :pageLanguage="pageLanguage"
                >
                    <template #title>
                        <Typography :variant="size === '25' ? 'h5' : 'h3'" as="h2">
                            {{ featuredItems[0].title }}
                        </Typography>
                    </template>
                </ContentSummary>
            </div>

            <ul class="feed-items">
                <li v-for="(item, index) in featuredItems.slice(1)" class="feed-item" :key="index">
                    <ContentSummary
                        v-bind="item"
                        headingVariant="body-large"
                        headingElement="h2"
                        :isDescriptionHidden="true"
                        :people="item.people"
                        class="item"
                        :theme="theme"
                        :pageLanguage="pageLanguage"
                    />
                </li>

                <li class="more-link">
                    <Button
                        v-if="pageLanguage === 'ru'"
                        :theme="theme"
                        variant="tertiary"
                        :url="topicOrRegionPath + '?lang=' + pageLanguage"
                        icon="arrow-forward-circled"
                        >{{ $t('more') }}
                    </Button>
                    <Button
                        v-else
                        :theme="theme"
                        variant="tertiary"
                        :url="topicOrRegionPath + '?lang=' + pageLanguage"
                        icon="arrow-forward-circled"
                        >{{ $t('more on') }} {{ topicOrRegionTitle }}</Button
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    topicOrRegion: {
        type: Object,
    },
    featuredContent: {
        type: Object,
    },
    size: {
        ...sizes,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});
const theme = inject('tierTheme', 'light');

const CSSClasses = computed(() => [`size-${props.size}`, `theme-${theme}`]);

const topicOrRegionPath = computed(() => {
    return props.topicOrRegion?.value?.path;
});
const topicOrRegionTitle = computed(() => {
    return props.topicOrRegion?.value?.title;
});
const featuredItems = computed(() => {
    return getContentFeedItems(props.featuredContent.feed, props.featuredContent.dynamic)?.map((item) => {
        const contentType = item.contentType;
        return {
            ...getContentTypeParsers(item, props.overrideImage)?.[contentType]?.(),
        };
    });
});

const featuredImage = computed(() => {
    return featuredItems.value[0]?.image;
});
</script>

<style lang="scss" scoped>
.tag-feed {
    background-color: color(blanc);
    padding-top: 1.1rem;
    &.theme-dark {
        background: color(noir, default, 0.2);
    }
}

.tag-wrapper {
    @include content-area-padding;
    margin-bottom: 1.1rem;

    @include media-query(phone) {
        @include padding-lr(var(--col-gutter));
    }
}

.tag {
    display: inline-block;
}

.feed-content {
    @include media-query(tablet) {
        @include grid;
        @include content-area-padding;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.feed-item {
    @include content-area-padding;
    border-bottom: 1px solid palette(border-light);
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;

    @include media-query(tablet) {
        grid-template-columns: auto;
        padding-left: 0;
        padding-right: 0;
    }

    @include media-query(phone) {
        @include padding-lr(var(--col-gutter));
    }
}

.featured-image {
    margin-bottom: 2.4rem;
    object-fit: cover;
    @include responsive(
        height,
        (
            default: 20.3rem,
            tablet: 16.1rem,
            phone: 18.3rem,
        )
    );
}

.feed-items {
    border-top: 1px solid palette(border-light);
}

.featured-item {
    @include content-area-padding;
    padding-bottom: 2.4rem;

    @include media-query(tablet) {
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;
    }

    @include media-query(phone) {
        @include padding-lr(var(--col-gutter));
    }
}

.more-link {
    padding: 2rem 2.4rem 2.4rem 2.4rem;

    @include media-query(tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
